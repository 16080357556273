.app {
margin:0 auto;
  max-width:970px;
  width:100%;
  position:relative;
  background-color: rgba(255,255,255,0.6);
  border:1px solid lightgray;
}
body {
  background: url('../public/images/americanflagwaving.png') fixed;
  font-weight: 400;
  
  min-width: 375px;
  user-select: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
}