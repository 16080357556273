
.finalResults {
  display:flex;
  position:relative;
  width:500px;
  height:500px;
  left:0px;
  background: white linear-gradient(315deg,#e9d4a8 0%,
  #eadac7 30%,
  rgb(173, 244, 212) 100%);
  padding:20px;
  border-radius: 10px;
  clip-path: polygon(0% 0%, 8% 0%, 100% 90%,100% 100%, 5% 100%, 0% 100%);
  
}

.whiteGradient {
  height:100%;
  width:100%;
  position:absolute;
  left:0px;
  top:0px;
  background: linear-gradient(45deg,white 0%,white 15%, rgba(255,255,255,0) 65%);
}

.finalWrapper {
  display:flex;
  position:relative;
  overflow: visible;
  min-width: 540px;
  margin:20px;
  left:50px;
  max-width:540px;
  top:20px;
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));

}
@media all and (max-width: 875px) {
  .finalWrapper {
    top:-20px;
    left:-70px;
    scale:0.7;
  }
}
.favorableFinal {
  position:absolute;
  left:-25px;
  top:-40px;
  font-weight: bold;font-size: 1.3em;
  background: rgb(201, 237, 212);
  border-radius:10px;
  padding:0px 10px 0px 15px;
  border:2px solid black;
}
.unfavorableFinal {
  position:absolute;
  left:453px;
  top:550px;
  font-size: 1.3em;
  font-weight: bold;
  border:2px solid black;
  padding:0px 5px 0px 15px;
  border-radius: 10px;
  width:auto;
  background: rgb(240, 222, 186);
}
.neutralFinal {
  position:absolute;
  width: auto;
  left:-25px;
  top:550px;
  font-size: 1.3em;
  font-weight: bold;
  border:2px solid black;
  padding:0px 5px 0px 15px;
  border-radius: 10px;
  background: white ;
}
.finalResultsCandidateIcon:hover {
  scale:1.1;
  z-index: 1;
}

.finalResultsCandidateName {
    background-color: white;
    border-radius: 3px;
    position: absolute;
    top: -45px;
    padding:3px;
    background-color: white;
    border:1px solid black;
}
.final {
  cursor:default;
}
.finalShownNote {
  position:absolute;
  right:-30px;
  top:0px;
    background-color: white;
    padding: 15px;
    text-align: center;
    border: 1px solid gray;
    border-radius: 15px;
    width:300px;
    z-index: 1;
    box-shadow: 5px 5px gray;
}