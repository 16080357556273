.app > h2 {
  padding:20px;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 2em;
  background-color: #fafafa;
  margin-top:0px;
  margin-bottom:0px;
  font-weight: 700;
  border-bottom:2px solid gray;
}
.countdownTimer {
  position:absolute;
  top:0px;
  right:5px;
  padding:5px;
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%,rgba(255, 239, 208,0.4) 50%);
  border-radius:5px;
}

@media all and (max-width: 875px) {
.countdownTimer {
  display:none;
}
}