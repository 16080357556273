h3 {
  padding-top:10px;
  padding-left:auto;
  padding-right:auto;
  padding-bottom: 10px;
  /* background: linear-gradient(90deg,rgba(255,255,255,0) 0%, rgba(255,250,250,1) 20%,rgba(255,250,250,1) 80%,rgba(255,255,255,0) 100%); */
  background-color:#fafafa;
  border:1px solid gray;
  text-align: center;
  border-radius: 10px;
  width:100%;
  max-width:450px;
  margin: 10px auto 50px auto;
}
.fa-star {
  background-color: rgb(90, 134, 165);
  color:white;
  padding:5px;
  border-radius: 25px;
  font-size: 1.3em;
  margin-top: auto;
  margin-bottom:auto;
  margin-right:0px;
  margin-left:0px;
}
.aspect {
  padding:20px;
  margin:20px;
  border:1px solid black;
  /* width:200px; */
  background-color: rgb(196, 220, 239);
  border-radius: 20px;
  display:flex;
  cursor:pointer;
  transition:scale 0.2s;
  background: rgb(196, 220, 239) linear-gradient(-110deg,rgb(196, 220, 239) 0%, #f6fbfd 100%);
  box-shadow: 4px 4px lightgray;
}
.aspect:hover {
  scale:1.04;
  box-shadow: 4px 4px darkgray;
}
i {
  margin-right: 10px;
}
.issuesContainer {
  border: 1px solid gray;
    position: relative;
    float: left;
    z-Index: 2;
    border-radius: 10px;
    margin:0px 00px 100px 20px;
    overflow: visible;
    width:270px;
    background-color:white;
    padding-left:20px;
}
.issuesContainer > h2 {
  font-size: 1.3em;
}
.candidate {
  padding:20px;
  margin:20px;
  border:1px solid black;
  border-radius: 20px;
  width:200px;
  display:flex;
  vertical-align: middle;
  align-items: center;
  background-color: rgb(243, 249, 255);
  cursor:grab;
  transition:scale 0.2s;
  user-select: none;
}
.candidate>img {
  user-select: none;
}
.candidate:hover {
  scale:1.04;
  
  box-shadow: 4px 4px lightgray;
}
.candidate:active {
  cursor:grabbing;
}
.candidateList:active {
  cursor:grabbing;
}

.aspectShown {
  position:relative;
  z-index: 1;
  float:left;
  text-align: center;
  margin-left:20px;
  background-color:rgb(228, 240, 250);
  border-radius: 5px;
  border:1px solid gray;
  margin-bottom: 80px;
  width:350px;
}
.aspectNameShown {
  position:relative;
  left:0;
  z-index:2;
  padding:20px;
  margin:20px;
  margin-right:0px;
  border:1px solid black;
  background-color: rgb(228, 240, 250);
  display:flex;
  width:240px;
  border-radius: 20px;
  border-right:none;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: 0px 4px rgb(104, 117, 129);
  font-size: 1.05em;
  font-weight: bold;
}


.resultsButton {
  /* margin:0px 0px 30px 60px; */
  padding:10px;
  border-radius: 10px;
  border:2px solid black;
  cursor:pointer;
  background: green;
  color:white;
  font-size:0.9em;
  vertical-align: middle;
  box-shadow: 3px 3px gray;
}

.resultsButton:hover {
  background-color: rgb(0, 149, 42);
  border:2px solid black;
  font-weight:bold;
  box-shadow: 4px 4px gray;
}
.headerNav {
  margin:20px auto 0px auto;
}
.headerNav > .fa-solid {
  margin:10px 20px;
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 10px;
  padding:5px;
}
.headerNav > .fa-solid:hover {
  scale:1.1;
}

.dragPlaceholder {
  padding:20px;
  margin:20px;
  border:3px dotted gray;
  border-radius: 20px;
  width:200px;
  vertical-align: middle;
  text-align: center;
  background-color: rgb(243, 249, 255);
  opacity:0.5;
  user-select: none;
}
.initialQuestionsHolder {
  margin: 0 auto 0 auto;
  width:550px;
}
.neutralInit {
  float:right;
  margin-top: auto;
}
@media all and (max-width: 875px) {
  .initialQuestionsHolder {
    width:100vw;
  }
  .neutralInit {
    width:100px;
  }
  h3 {
    margin-bottom:20px;
  }
  .fa-star {
    display:none;
  }
  .aspectNameShown,.aspect {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;  
    max-width: fit-content;
    font-size:1em;
    padding:10px 8px 10px 15px;
    margin:5px;
  background: #fefefe;
} 
.aspectNameShown {
  background: rgb(228, 240, 250);
}

.issuesContainer {
  float:none;
  margin: 0px auto 10px auto;
  width:100%;
  max-width: fit-content;
  min-height: 75px;
  display:flex;
  align-items: center;
  flex-direction: column;
  padding:0px;
}

.issuesList {
  display:flex;
  justify-items:center;
  justify-content:space-evenly;
  width:100%;
}
.issuesContainer > h2 {
  width:100%;
  font-size:1em;
  position:relative;
  left:10px;
}
.aspectName {
  display:none;
}
.aspectShown {
  float:none;
  margin:0px auto 80px auto;
}
}
.unfavorable {
  background: linear-gradient(#fff 0%, rgb(252, 224, 228) 100%);
}
.favorable {
  background: linear-gradient(rgb(224, 252, 224) 0%, #fff 100%);
}
.divider {
  list-style-type: none;
  margin-left: -40px;
  padding-top:20px;
  padding-bottom:20px;
}
.resultsButtonBack {
  margin-top: 10px;
  margin-bottom: 0px;
  scale:0.7;
  background: lightgray;
  color:black;
  border:none;
}
.resultsButtonBack:hover {
  background: #eaeaea;
  font-weight: normal;
  border:none;
}
.infoBar {
  opacity:0.8;
  background-color: lightgray;
  color:white;
  position: absolute;
  right: 0px;
  top: 2px;
  height:70px;
  padding-top: 35px;
  padding-left:10px;
  border-top-right-radius: 15px;
  border-bottom-right-radius:15px;
  cursor: pointer
}
.infoBar:hover {
  opacity:1;
  font-size:1.2em;
}

.notes {
  padding:10px;
  cursor: default;
  border-radius: 10px;
  background: linear-gradient(90deg, rgb(224, 239, 255) 0%, #fff 100%);
  border:2px solid gray;
  width:365px;
}
.notes h5 {
  margin: 10px 0px 0px 0px;
}

.bigGreenCheck {
  position:absolute;
  padding:70px;
left:35%;  font-size: 10em;
  color:darkgreen;
  opacity:0.8;
  border-radius:10px;
}
.initialCandidate {
  cursor: grab;
}

.unreviewed {
  font-weight: bold;
  text-decoration: underline;
  color: rgb(72, 56, 15);
}
.toReview {
  color: darkgoldenrod;
  text-decoration: none;
  position:absolute;
  left:0px;
  top:0px;
  border-radius: 10px;
  box-shadow: 2px 2px gray;
}
.needsReview {
  display:none;
  position:absolute;
  border:1px solid black;
  background:white;
  border-radius: 5px;
  padding:5px;
}
.needsReview:hover {
  display:block;
}
.toReview:hover~.needsReview {
  display:block;
}
.takeaways {
  background-color: white;
  width:80%;
  margin: 0 auto 0 auto;
  text-align: center;
  font-size: larger;
  border: 1px solid gray;
  border-radius: 15px;
}
