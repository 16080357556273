h2.footer {
  clear:both;
  padding:10px;
  margin-left:0px;
  margin-right:0px;
  margin: 100px 0px -10px 0px;
  margin-top:100px;
  border-top:1px solid black;
}

@media all and (max-width: 875px) {
  h2.footer {
    font-size:1.5em;
  }
}